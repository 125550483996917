/* Page Setup */
:root {
	--page-bg-color: #0b0b0b;
	--default-font-family: "Raleway", sans-serif;
	--secondary-font-family: "Panton", sans-serif;
	--support-btn-bg-color: rgba(255, 255, 255, 0.175);
	--support-btn-bg-color-hover: rgba(255, 255, 255, 0.25);
	--partner-disclaimer-bg-color: rgba(193, 78, 79, 0.65);
}
html {
	background-color: var(--page-bg-color);
	scroll-behavior: smooth;
}
body {
	font-family: var(--default-font-family);
	background: #090909 url("/assets/images/background.webp");
	background-size: cover;
	background-position: center;
	padding: 45px;
	overflow-x: hidden;
}
.no-drag {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

/* Navigation */
nav {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
nav > img {
	height: 72px;
	width: 72px;
}
nav > div {
	display: flex;
	flex-direction: row;
}
nav > div > a {
	font-family: var(--secondary-font-family);
	background-color: var(--support-btn-bg-color);
	align-self: center;
	text-transform: uppercase;
	text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.16);
	text-decoration: none;
	color: white;
	letter-spacing: 1px;
	padding: 15px 20px;
	border-radius: 20px;
	transition: 0.5s all;
	text-align: center;
}
nav > div > a:hover {
	background-color: var(--support-btn-bg-color-hover);
	color: white;
}

/* Partner Disclaimer */
.partner-disclaimer {
	background-color: var(--partner-disclaimer-bg-color);
	margin-right: 15px;
	padding: 7.5px 15px;
	align-self: center;
	color: white;
	border-radius: 15px;
	font-size: 14px;
}
.partner-disclaimer > span {
	font-weight: 900;
	font-style: italic;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.text-information .partner-disclaimer {
	display: none;
	margin-top: 30px;
}

/* Main Content */
#content {
	display: flex;
	height: calc(100vh - 90px - 72px - 64px);
}
#content > .row > .col-12 {
	margin-bottom: 25px;
}
.text-information {
	width: 630px;
	max-width: 100%;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	animation: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 fadeInLeft;
}

.text-information > h1 {
	font-family: var(--secondary-font-family);
	font-size: 3.3rem;
}
.text-information > p {
	font-size: 1.125rem;
	opacity: 0.8;
	width: 600px;
	max-width: 100%;
	padding-top: 12.5px;
	padding-bottom: 12.5px;
}
.text-information > p > span {
	font-weight: bolder;
}
.text-information > img {
	width: 428px;
	max-width: 100%;
	object-fit: contain;
}
#content > .row {
	width: 100%;
}
.network-display {
	position: relative;
	background-color: #000000;
	height: 100%;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
}
.network-display:hover > div {
	transform: scale(1.1);
}

/* purgecss ignore */
button.network-display {
	padding: 0;
	outline: none;
	box-shadow: none;
	border: none;
}

.network-display > div {
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-position: center;
	background-size: cover;
	transition: 1s all;
	position: relative;
}
.network-display > div > div {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.network-display .network-logo {
	height: 160px;
	width: 160px;
	object-fit: contain;
	filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.2));
}
.network-display:hover .network-bg {
	opacity: 0.5;
}
.network-display .network-bg {
	opacity: 0.75;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: all 1s;
}
.network-display .network-bg-grey {
	filter: grayscale(1);
}
.network-display h2 {
	margin-top: 20px;
	font-family: var(--secondary-font-family);
	color: white;
	letter-spacing: 1px;
	text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.16);
}
.network-display .native-badge {
	color: white;
	font-size: 8px;
	font-style: italic;
	background-color: #c345ff;
	width: fit-content;
	padding: 5px 10px;
	border-radius: 30px;
	font-weight: 900;
	letter-spacing: 1px;
	text-transform: uppercase;
}

/* Network Display Loading */
.service-container.network-display-loading {
	filter: grayscale(1);
}
.service-container.network-display-loading .loading-overlay {
	display: block;
}
.service-container.network-display-loading .network-display > div > div:not(.loading-overlay) {
	display: none !important;
}
.service-container .loading-overlay {
	display: none;
}
.service-container .spinner-grow {
	margin-bottom: 20px;
	filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.2));
	color: white;
	height: 140px;
	width: 140px;
	border-width: 20px;
}

/* Footer */
footer {
	margin-top: 20px;
	color: white;
	opacity: 0.4;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
}

footer > div:first-child {
	letter-spacing: 1px;
	font-weight: bold;
}

footer > .socials {
	display: flex;
	align-items: center;
	height: 100%;
}

/* purgecss ignore */
footer > .socials > a > svg {
	height: 22px;
}

footer > .socials > a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	height: 100%;
	margin: 0px 15px;
	min-height: 24px;
	min-width: 24px;
	transition: 0.25s all;
	text-decoration: none;
	cursor: pointer;
}
footer > .socials > a:hover {
	opacity: 0.7;
	text-decoration: none;
}

/* Crypto Holder */
#crypto-holder {
	display: none;
}

/* Thank you coinbase for the nested divs */

#content > .row > div > .service-container > div > a {
	display: block;
	height: 100%;
	animation: fadeInUp 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 both;
}

#content > .row.services-container > div:nth-child(1) > .service-container > div > a {
	animation-delay: 0.2s;
}
#content > .row.services-container > div:nth-child(2) > .service-container > div > a {
	animation-delay: 0.35s;
}
#content > .row.services-container > div:nth-child(3) > .service-container > div > a {
	animation-delay: 0.45s;
}

#content > .row > div > .service-container,
#content > .row > div > .service-container > div {
	height: 100%;
}

/* Media Queries */
@media (max-width: 1375px) {
	#content > .row > div {
		height: 350px;
		margin-bottom: 40px;
	}
	#content {
		flex-direction: column;
		align-items: center;
		height: unset;
	}
	#content > .text-information {
		text-align: center;
		margin-bottom: 40px;
	}
	#content > .text-information > img {
		align-self: center;
	}
}
@media (max-width: 1250px) {
	nav .partner-disclaimer {
		display: none;
	}
	.text-information .partner-disclaimer {
		display: block;
		margin-right: 0px;
	}
}
@media (max-width: 825px) {
	#content > .row {
		justify-content: space-between;
		flex-direction: column;
	}
	#content > .row > div {
		width: 100%;
	}
	#content > .row > .col-12 {
		margin-bottom: 40px;
	}
}
@media (max-width: 575px) {
	#content > .text-information > h1 {
		font-size: 2rem;
	}
	#content > .text-information > p {
		font-size: 0.9rem;
	}
	#content > .text-information .partner-disclaimer {
		font-size: 13px;
	}
}
@media (max-width: 520px) {
	footer {
		flex-direction: column-reverse;
	}
	footer > div:first-child {
		margin-top: 20px;
		text-align: center;
	}
	footer > div:last-child {
		align-self: center;
		text-align: center;
	}
	body {
		padding-left: 25px;
		padding-right: 25px;
	}
}
@media (max-width: 375px) {
	nav {
		justify-content: center;
	}
	nav > a {
		display: none;
	}
	nav > div > a {
		margin-left: 30px;
	}
}
@media (max-width: 325px) {
	nav > div > a {
		display: none;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}
