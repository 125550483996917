/* Panton */
@font-face {
	font-family: "Panton";
	font-style: italic;
	font-weight: 400;
	src: local("Panton"), url("/assets/fonts/Panton-BlackitalicCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: normal;
	font-weight: 290;
	src: local("Panton"), url("/assets/fonts/Panton-LightCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: italic;
	font-weight: 300;
	src: local("Panton"), url("/assets/fonts/Panton-LightitalicCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: normal;
	font-weight: 390;
	src: local("Panton"), url("/assets/fonts/Panton-BlackCaps.woff2") format("woff");
	font-display: swap;
}

/* Raleway */
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 400;
	src: local("Raleway Italic"), local("Raleway-Italic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 400;
	src: local("Raleway Italic"), local("Raleway-Italic"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 600;
	src: local("Raleway SemiBold Italic"), local("Raleway-SemiBoldItalic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptpg8zYS_SKggPNyCgwkqV_DNCb_Vo.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 600;
	src: local("Raleway SemiBold Italic"), local("Raleway-SemiBoldItalic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptpg8zYS_SKggPNyCgwkqV_AtCb.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	src: local("Raleway Light"), local("Raleway-Light"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	src: local("Raleway Light"), local("Raleway-Light"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyC0ITw.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	src: local("Raleway Medium"), local("Raleway-Medium"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	src: local("Raleway Medium"), local("Raleway-Medium"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 800;
	src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 800;
	src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}

/* Roboto */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
