/* Page Setup */
:root {
	--page-bg-color: #0b0b0b;
	--default-font-family: "Raleway", sans-serif;
	--secondary-font-family: "Panton", sans-serif;
	--support-btn-bg-color: rgba(255, 255, 255, 0.175);
	--support-btn-bg-color-hover: rgba(255, 255, 255, 0.25);
	--partner-disclaimer-bg-color: rgba(193, 78, 79, 0.65);
}
html {
	background-color: var(--page-bg-color);
	scroll-behavior: smooth;
}
body {
	font-family: var(--default-font-family);
	background: #090909 url("/assets/images/background.webp");
	background-size: cover;
	background-position: center;
	padding: 45px;
	overflow-x: hidden;
}
.no-drag {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-drag: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

/* Navigation */
nav {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
}
nav > img {
	height: 72px;
	width: 72px;
}
nav > div {
	display: flex;
	flex-direction: row;
}
nav > div > a {
	font-family: var(--secondary-font-family);
	background-color: var(--support-btn-bg-color);
	align-self: center;
	text-transform: uppercase;
	text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.16);
	text-decoration: none;
	color: white;
	letter-spacing: 1px;
	padding: 15px 20px;
	border-radius: 20px;
	transition: 0.5s all;
	text-align: center;
}
nav > div > a:hover {
	background-color: var(--support-btn-bg-color-hover);
	color: white;
}

/* Partner Disclaimer */
.partner-disclaimer {
	background-color: var(--partner-disclaimer-bg-color);
	margin-right: 15px;
	padding: 7.5px 15px;
	align-self: center;
	color: white;
	border-radius: 15px;
	font-size: 14px;
}
.partner-disclaimer > span {
	font-weight: 900;
	font-style: italic;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.text-information .partner-disclaimer {
	display: none;
	margin-top: 30px;
}

/* Main Content */
#content {
	display: flex;
	height: calc(100vh - 90px - 72px - 64px);
}
#content > .row > .col-12 {
	margin-bottom: 25px;
}
.text-information {
	width: 630px;
	max-width: 100%;
	color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	-webkit-animation: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 fadeInLeft;
	        animation: 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 fadeInLeft;
}

.text-information > h1 {
	font-family: var(--secondary-font-family);
	font-size: 3.3rem;
}
.text-information > p {
	font-size: 1.125rem;
	opacity: 0.8;
	width: 600px;
	max-width: 100%;
	padding-top: 12.5px;
	padding-bottom: 12.5px;
}
.text-information > p > span {
	font-weight: bolder;
}
.text-information > img {
	width: 428px;
	max-width: 100%;
	-o-object-fit: contain;
	   object-fit: contain;
}
#content > .row {
	width: 100%;
}
.network-display {
	position: relative;
	background-color: #000000;
	height: 100%;
	width: 100%;
	cursor: pointer;
	overflow: hidden;
}
.network-display:hover > div {
	transform: scale(1.1);
}
button.network-display {
	padding: 0;
	outline: none;
	box-shadow: none;
	border: none;
}

.network-display > div {
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	background-position: center;
	background-size: cover;
	transition: 1s all;
	position: relative;
}
.network-display > div > div {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.network-display .network-logo {
	height: 160px;
	width: 160px;
	-o-object-fit: contain;
	   object-fit: contain;
	filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.2));
}
.network-display:hover .network-bg {
	opacity: 0.5;
}
.network-display .network-bg {
	opacity: 0.75;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	   object-fit: cover;
	transition: all 1s;
}
.network-display .network-bg-grey {
	filter: grayscale(1);
}
.network-display h2 {
	margin-top: 20px;
	font-family: var(--secondary-font-family);
	color: white;
	letter-spacing: 1px;
	text-shadow: 0px 3px 0px rgba(0, 0, 0, 0.16);
}
.network-display .native-badge {
	color: white;
	font-size: 8px;
	font-style: italic;
	background-color: #c345ff;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 5px 10px;
	border-radius: 30px;
	font-weight: 900;
	letter-spacing: 1px;
	text-transform: uppercase;
}

/* Network Display Loading */
.service-container.network-display-loading {
	filter: grayscale(1);
}
.service-container.network-display-loading .loading-overlay {
	display: block;
}
.service-container.network-display-loading .network-display > div > div:not(.loading-overlay) {
	display: none !important;
}
.service-container .loading-overlay {
	display: none;
}
.service-container .spinner-grow {
	margin-bottom: 20px;
	filter: drop-shadow(0px 3px 0px rgba(0, 0, 0, 0.2));
	color: white;
	height: 140px;
	width: 140px;
	border-width: 20px;
}

/* Footer */
footer {
	margin-top: 20px;
	color: white;
	opacity: 0.4;
	display: flex;
	justify-content: space-between;
	font-size: 16px;
}

footer > div:first-child {
	letter-spacing: 1px;
	font-weight: bold;
}

footer > .socials {
	display: flex;
	align-items: center;
	height: 100%;
}
footer > .socials > a > svg {
	height: 22px;
}

footer > .socials > a {
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	height: 100%;
	margin: 0px 15px;
	min-height: 24px;
	min-width: 24px;
	transition: 0.25s all;
	text-decoration: none;
	cursor: pointer;
}
footer > .socials > a:hover {
	opacity: 0.7;
	text-decoration: none;
}

/* Crypto Holder */

/* Thank you coinbase for the nested divs */

#content > .row > div > .service-container > div > a {
	display: block;
	height: 100%;
	-webkit-animation: fadeInUp 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 both;
	        animation: fadeInUp 0.5s cubic-bezier(0.22, 0.61, 0.36, 1) 0s 1 both;
}

#content > .row.services-container > div:nth-child(1) > .service-container > div > a {
	-webkit-animation-delay: 0.2s;
	        animation-delay: 0.2s;
}
#content > .row.services-container > div:nth-child(2) > .service-container > div > a {
	-webkit-animation-delay: 0.35s;
	        animation-delay: 0.35s;
}
#content > .row.services-container > div:nth-child(3) > .service-container > div > a {
	-webkit-animation-delay: 0.45s;
	        animation-delay: 0.45s;
}

#content > .row > div > .service-container,
#content > .row > div > .service-container > div {
	height: 100%;
}

/* Media Queries */
@media (max-width: 1375px) {
	#content > .row > div {
		height: 350px;
		margin-bottom: 40px;
	}
	#content {
		flex-direction: column;
		align-items: center;
		height: unset;
	}
	#content > .text-information {
		text-align: center;
		margin-bottom: 40px;
	}
	#content > .text-information > img {
		align-self: center;
	}
}
@media (max-width: 1250px) {
	nav .partner-disclaimer {
		display: none;
	}
	.text-information .partner-disclaimer {
		display: block;
		margin-right: 0px;
	}
}
@media (max-width: 825px) {
	#content > .row {
		justify-content: space-between;
		flex-direction: column;
	}
	#content > .row > div {
		width: 100%;
	}
	#content > .row > .col-12 {
		margin-bottom: 40px;
	}
}
@media (max-width: 575px) {
	#content > .text-information > h1 {
		font-size: 2rem;
	}
	#content > .text-information > p {
		font-size: 0.9rem;
	}
	#content > .text-information .partner-disclaimer {
		font-size: 13px;
	}
}
@media (max-width: 520px) {
	footer {
		flex-direction: column-reverse;
	}
	footer > div:first-child {
		margin-top: 20px;
		text-align: center;
	}
	footer > div:last-child {
		align-self: center;
		text-align: center;
	}
	body {
		padding-left: 25px;
		padding-right: 25px;
	}
}
@media (max-width: 375px) {
	nav {
		justify-content: center;
	}
	nav > a {
		display: none;
	}
	nav > div > a {
		margin-left: 30px;
	}
}
@media (max-width: 325px) {
	nav > div > a {
		display: none;
	}
}

@-webkit-keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translateX(-250px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@-webkit-keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translateY(60px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

/* Panton */
@font-face {
	font-family: "Panton";
	font-style: italic;
	font-weight: 400;
	src: local("Panton"), url("/assets/fonts/Panton-BlackitalicCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: normal;
	font-weight: 290;
	src: local("Panton"), url("/assets/fonts/Panton-LightCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: italic;
	font-weight: 300;
	src: local("Panton"), url("/assets/fonts/Panton-LightitalicCaps.woff2") format("woff");
	font-display: swap;
}
@font-face {
	font-family: "Panton";
	font-style: normal;
	font-weight: 390;
	src: local("Panton"), url("/assets/fonts/Panton-BlackCaps.woff2") format("woff");
	font-display: swap;
}

/* Raleway */
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 400;
	src: local("Raleway Italic"), local("Raleway-Italic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNyCg4Q4FqPfE.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 400;
	src: local("Raleway Italic"), local("Raleway-Italic"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptsg8zYS_SKggPNyCg4TYFq.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 600;
	src: local("Raleway SemiBold Italic"), local("Raleway-SemiBoldItalic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptpg8zYS_SKggPNyCgwkqV_DNCb_Vo.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: italic;
	font-weight: 600;
	src: local("Raleway SemiBold Italic"), local("Raleway-SemiBoldItalic"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptpg8zYS_SKggPNyCgwkqV_AtCb.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	src: local("Raleway Light"), local("Raleway-Light"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 300;
	src: local("Raleway Light"), local("Raleway-Light"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIYqWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyCMIT5lu.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 400;
	src: local("Raleway"), local("Raleway-Regular"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptug8zYS_SKggPNyC0ITw.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	src: local("Raleway Medium"), local("Raleway-Medium"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 500;
	src: local("Raleway Medium"), local("Raleway-Medium"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwN4rWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 600;
	src: local("Raleway SemiBold"), local("Raleway-SemiBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwPIsWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 700;
	src: local("Raleway Bold"), local("Raleway-Bold"), url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwJYtWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 800;
	src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqhPAMif.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Raleway";
	font-style: normal;
	font-weight: 800;
	src: local("Raleway ExtraBold"), local("Raleway-ExtraBold"),
		url(https://fonts.gstatic.com/s/raleway/v12/1Ptrg8zYS_SKggPNwIouWqZPAA.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}

/* Roboto */
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 300;
	src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 400;
	src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v18/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
	unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
	unicode-range: U+1F00-1FFF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
	unicode-range: U+0370-03FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
	unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
	font-display: swap;
}
@font-face {
	font-family: "Roboto";
	font-style: normal;
	font-weight: 700;
	src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v18/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212,
		U+2215, U+FEFF, U+FFFD;
	font-display: swap;
}

:root {
	--bs-blue: #0d6efd;
	--bs-indigo: #6610f2;
	--bs-purple: #6f42c1;
	--bs-pink: #d63384;
	--bs-red: #dc3545;
	--bs-orange: #fd7e14;
	--bs-yellow: #ffc107;
	--bs-green: #198754;
	--bs-teal: #20c997;
	--bs-cyan: #0dcaf0;
	--bs-white: #fff;
	--bs-gray: #6c757d;
	--bs-gray-dark: #343a40;
	--bs-primary: #0d6efd;
	--bs-secondary: #6c757d;
	--bs-success: #198754;
	--bs-info: #0dcaf0;
	--bs-warning: #ffc107;
	--bs-danger: #dc3545;
	--bs-light: #f8f9fa;
	--bs-dark: #212529;
	--bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
		"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	--bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
	--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}
*,
::after,
::before {
	box-sizing: border-box;
}
@media (prefers-reduced-motion: no-preference) {
	:root {
		scroll-behavior: smooth;
	}
}
body {
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: transparent;
}
.h1,
.h2,
h1,
h2 {
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-weight: 500;
	line-height: 1.2;
}
.h1,
h1 {
	font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
	.h1,
	h1 {
		font-size: 2.5rem;
	}
}
.h2,
h2 {
	font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
	.h2,
	h2 {
		font-size: 2rem;
	}
}
p {
	margin-top: 0;
	margin-bottom: 1rem;
}
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}
.mark,
mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}
a {
	color: #0d6efd;
	text-decoration: underline;
}
a:hover {
	color: #0a58ca;
}
a:not([href]):not([class]),
a:not([href]):not([class]):hover {
	color: inherit;
	text-decoration: none;
}
code {
	font-family: var(--bs-font-monospace);
	font-size: 1em;
	direction: ltr;
	unicode-bidi: bidi-override;
}
code {
	font-size: 0.875em;
	color: #d63384;
	word-wrap: break-word;
}
a > code {
	color: inherit;
}
img {
	vertical-align: middle;
}
iframe {
	border: 0;
}
.lead {
	font-size: 1.25rem;
	font-weight: 300;
}
.row {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
	display: flex;
	flex-wrap: wrap;
	margin-top: calc(var(--bs-gutter-y) * -1);
	margin-right: calc(var(--bs-gutter-x) * -0.5);
	margin-left: calc(var(--bs-gutter-x) * -0.5);
}
.row > * {
	flex-shrink: 0;
	width: 100%;
	max-width: 100%;
	padding-right: calc(var(--bs-gutter-x) * 0.5);
	padding-left: calc(var(--bs-gutter-x) * 0.5);
	margin-top: var(--bs-gutter-y);
}
.col-6 {
	flex: 0 0 auto;
	width: 50%;
}
.col-12 {
	flex: 0 0 auto;
	width: 100%;
}
.nav {
	display: flex;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
}
@-webkit-keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}
@keyframes progress-bar-stripes {
	0% {
		background-position-x: 1rem;
	}
}
@-webkit-keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}
@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}
@-webkit-keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}
@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}
	50% {
		opacity: 1;
		transform: none;
	}
}
.spinner-grow {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	vertical-align: -0.125em;
	background-color: currentColor;
	border-radius: 50%;
	opacity: 0;
	-webkit-animation: 0.75s linear infinite spinner-grow;
	animation: 0.75s linear infinite spinner-grow;
}
@media (prefers-reduced-motion: reduce) {
	
	.spinner-grow {
		-webkit-animation-duration: 1.5s;
		animation-duration: 1.5s;
	}
}

button.coinbase-commerce-button {
	border-radius: 6px;
	background-color: #0667d0;
	background: linear-gradient(#0667d0, #0655ab);
	color: white;
	height: 40px;
	font-size: 14px;
	font-family: Avenir Next, sans-serif;
	font-weight: 500;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	user-drag: none;
	-webkit-user-drag: none;
	text-decoration: none;
	cursor: pointer;
}

button.coinbase-commerce-button:hover {
	background: #0666d0;
}
button.coinbase-commerce-button:active {
	background: #0655ab;
}
button.coinbase-commerce-button:focus {
	outline: none;
}
button.coinbase-commerce-button:disabled {
	background: #7d95b6;
	color: rgba(0, 0, 0, 0.4);
	cursor: not-allowed;
}

button.coinbase-commerce-button > span {
	color: white;
	font: normal 500 14px/20px -apple-system, BlinkMacSystemFont, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue",
		"Lucida Grande", sans-serif;
	letter-spacing: 0;
	overflow: hidden;
	text-align: center;
	text-overflow: ellipsis;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
	white-space: nowrap;
}

div.commerce-loading-spinner {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -20px;
	margin-left: -20px;
	width: 40px;
	height: 40px;
	border: 3px solid rgba(6, 103, 208, 0.05);
	border-radius: 100%;
	border-top-color: white;
	-webkit-animation: spin 1s infinite linear;
	        animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
	33% {
		transform: rotate(90deg);
	}
	66% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spin {
	33% {
		transform: rotate(90deg);
	}
	66% {
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

div.coinbase-commerce-iframe-container {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 99998;

	background-color: rgba(0, 0, 0, 0.5);
}

iframe.coinbase-commerce-iframe {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 99999;
	height: 100%;
	width: 100%;
	border: none;
}

